<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="External Access Groups"
      subtitle="Browse and Manage your Access Groups!"
      :badge="this.total"
    />
    <v-row class="mt-0 mb-1 justify-space-between">
      <v-col>
        <v-btn color="info" @click="add" v-if="haveCreateAccess()"
          ><i class="fal fa-plus mr-2"></i>Add Access Group</v-btn
        >
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="8">
        <div class="d-flex flex-row align-center justify-end flex-wrap" style="gap: 0.5rem">
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details=""
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      @click:row="rowClicked"
      class="elevation-2 companies-table table-sticky-header-exclude-footer"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <h3 class="ma-0">{{ item.name }}</h3>
      </template>
      <template v-slot:[`item.users`]="{ item }">
        <users-avatars :users="item.users"></users-avatars>
      </template>
      <template v-slot:[`item.createdBy`]="{ item }">
        <user-avatar :user="item.createdBy" icon></user-avatar>
      </template>
      <template v-slot:[`item.updatedBy`]="{ item }">
        <user-avatar :user="item.updatedBy" icon></user-avatar>
      </template>

      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>
      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu" v-if="haveUpdateAccess()">
            <v-list-item @click.stop="update(item.id)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Update</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click.stop="del(item.id)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <edit-access-group
      @onDeleteAccessGroup="del(selected.id)"
      @save="onAccessGroupSaved"
      @close="onAccessGroupClosed"
      ref="editAccessGroup"
    ></edit-access-group>
  </v-container>
</template>

<script>
import ApiService from "../services/access-group-service.js";
import EditAccessGroup from "../components/EditAccessGroup.vue";
import accessGroupHeader from "../config/tables/access-group.header";
import UsersAvatars from "../../../Shared/components/UsersAvatars.vue";
import FilterManager from "../../../Shared/components/FilterManager/FilterManager.vue";
import { UserFilterSettings } from "../../../Shared/components/FilterManager/FilterSettings";

export default {
  components: {
    EditAccessGroup,
    UsersAvatars,
    FilterManager,
  },
  data() {
    return {
      storageKey: "Access Groups",
      paramId: null,
      selectedFilters: [],
      entities: [],
      selected: {},
      total: 0,
      search: "",
      valid: false,
      accessGroupTimerId: null,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
        createdByIds: [],
        updatedByIds: [],
      },
      loadingStates: {
        table: false,
      },
      headers: accessGroupHeader,
      defaultHeaders: [],
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.$refs.mainSearch.focus();
    this.checkForSingleAccessGroupRoute(this.$route);
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      this.selectedFilters = [createdByFilterSettings, updatedByFilterSettings];
    },
    getData() {
      this.$backToTop();
      this.loadingStates.table = true;
      ApiService.query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    haveCreateAccess(e) {
      return this.$has(["ProjectAccessList.Create"]);
    },
    haveUpdateAccess(e) {
      return this.$has(["ProjectAccessList.Update"]);
    },
    add() {
      this.selected = {};
      this.$refs.editAccessGroup.open(null);
    },
    update(id) {
      this.selected = { id: id };
      setTimeout(() => {
        const path = `/external-access-groups/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
        this.$refs.editAccessGroup.open(id, true);
      });
    },
    view(id) {
      setTimeout(() => {
        const path = `/external-access-groups/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
        this.$refs.editAccessGroup.open(id);
      });
    },
    rowClicked(row) {
      this.view(row.id);
    },
    del(id) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this Access Group?`,
          title: `Delete Access Group?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("Item deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                    this.$refs.editAccessGroup.closeUpdateSlideout();
                    this.onAccessGroupClosed();
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onAccessGroupClosed() {
      const path = `/external-access-groups`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    onAccessGroupSaved(teamData, isNewTeam) {
      this.$log("onAccessGroupSaved", teamData, isNewTeam);
      if (isNewTeam) {
        this.addToArr(this.entities, teamData);
        this.total++;
      } else {
        this.updateArr(this.entities, teamData);
      }
    },
    getDataDebounced() {
      if (this.accessGroupTimerId == null) {
        this.accessGroupTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.accessGroupTimerId);

      // delay new call 400ms
      this.accessGroupTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    checkForSingleAccessGroupRoute(route) {
      if (route.params.id) {
        this.paramId = Number.parseInt(route.params.id);
        this.view(this.paramId);
      } else this.paramId = null;
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
    $route: {
      handler(newRoute) {
        s;
      },
      deep: true,
    },
  },
};
</script>
